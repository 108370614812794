import * as Types from '../../../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAddressMutationVariables = Types.Exact<{
  data: Types.AddressUpdateInput;
  addressId: Types.Scalars['String'];
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateOneAddress?: { __typename?: 'Address', id: string, street_number?: string | null, route?: string | null, country?: string | null, postal_code?: string | null, locality?: string | null, administrative_area_level_1?: string | null, administrative_area_level_2?: string | null } | null };

export type FullAddressFragment = { __typename?: 'Address', id: string, street_number?: string | null, route?: string | null, country?: string | null, postal_code?: string | null, locality?: string | null, administrative_area_level_1?: string | null, administrative_area_level_2?: string | null };

export const FullAddressFragmentDoc = gql`
    fragment fullAddress on Address {
  id
  street_number
  route
  country
  postal_code
  locality
  administrative_area_level_1
  administrative_area_level_2
}
    `;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($data: AddressUpdateInput!, $addressId: String!) {
  updateOneAddress(where: {id: $addressId}, data: $data) {
    ...fullAddress
  }
}
    ${FullAddressFragmentDoc}`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;