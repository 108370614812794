import React from 'react';
import Kaveh from './kaveh.jpg';
import Helen from './helen.jpg';
import David from './david.jpg';
import Bio from './Bio';

import './about.css';
// import { Footer } from '../Footer';
//import { Link } from 'react-router-dom'

const About: React.FC = () => {
  return (
    <div className='about'>
      <div className='above-fold'>
        <div>
          <h1>
            99barrels allows you to sell directly to both your wholesale and
            retail customers.
          </h1>
          <p>
            Add your products along with their wholesale and retail pricelists.
            Your wholesale prices remain private; we vet which businesses are
            allowed to shop wholesale channels.
          </p>
        </div>
        <div>
          <h1>
            Free to setup and use.
            <br /> Simple {process.env.REACT_APP_PERCENT}% commission when you
            make a sale.{' '}
          </h1>
          <p>
            Stop paying outrageous setup and monthly fees just to sell your
            products online. We take a flat {process.env.REACT_APP_PERCENT}%
            commission, inclusive of all processing fees.
          </p>
          <p>
            We know sometimes it can be feast and famine, so you won't pay us a
            cent when you're not selling yourself.
          </p>
        </div>
      </div>
      <h1>About the founders</h1>
      <div className='bio-container'>
        <Bio content={kaveh} image={Kaveh} />
        <Bio content={helen} image={Helen} />
        <Bio content={david} image={David} />
      </div>
    </div>
  );
};

export default About;

const helen = `
<p>Like many of our own stories, Helen's journey in product management was not planned. Born and raised in Brooklyn, New York, she has always been surrounded by a limitless options of beverages, both alcoholic and non-alcoholic.</p>
 
<p>Envisioning at first becoming a politician or diplomat, Helen found herself enamored with the digital world after building a no-code mobile app for an educational non-profit in South Africa. Ever since, her passion has been to leverage digital technology to better our communities and empower one another.</p>
 
<p>She is excited to bring 99barrels to the world, and expand each of our tastebuds one beverage at a time.</p>

`;
const kaveh = `
<p>
Kaveh's career has been largely grounded in tech as a developer +
solution architect. He has worked on large-scale enterprise
projects/applications for household corporate giants as well as
smaller, boutique/chic projects for fashion juggernauts.{' '}
</p>
<p>
Kaveh is passionate about technology + design, and leveraged those
immense talents to build the dynamic 99barrels platform. Kaveh's
family began making wine over in Sonoma County, California 20+ years
ago when they decided to plant vines on farmland that had been used
as pasture for their horses.
</p>
<p>
Kaveh worked hands-on in the management of the vineyard, wine making
and wine sales and has a keen understanding of the challenges and
obstacles small batch, craft beverage producers face in this
highly-regulated global market. 99barrels is the culmination of
Kaveh's background in tech, design, and wine business management,
which he built to empower and support small beverage businesses all
over the world. You can reach him at kaveh@99barrels.co.
</p>`;

const david = `
<p>David’s career journey began in New York City, where he honed his skills in finance and strategic innovation. Initially navigating diverse financial roles, David’s passion for technology and its transformative potential led him to co-found 99barrels. Additionally, as a certified wine sommelier, David brings a deep understanding and appreciation of wine to the company. His expertise spans finance, strategic innovation, supply chains in the alcoholic beverage industry, and the food and beverage industry, enabling him to integrate cutting-edge technology into the wine industry. His mission is to make wine more accessible, enjoyable, and sustainable. David is excited to revolutionize the wine experience and enhance every aspect of wine production and enjoyment.</p>`;
