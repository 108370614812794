import React from 'react'
export function BenefitTile({ headline, blurb }) {
    return (
        <div className='benefit-tile'>
            <div className='headline'>
                <span> {headline}</span>
                <span>{'→'}</span>
            </div>
            <p> {blurb} </p>
        </div>
    )
}