import React from 'react';
import AppContext from '../../context/AppContext';
import Obfuscate from 'react-obfuscate';
import './footer.css';
export const Footer = (props) => {
  return (
    <AppContext.Consumer>
      {(appContext) => (
        <div id='footer'>
          <div id='footer-logo'>
            <img
              alt='logo'
              id='footer-sketch-logo'
              src='/images/99b_logo_high_detail.svg'
            />
          </div>
          <div id='footer-contact'>
            <h1>
              <a href='/about'>About Us</a>
            </h1>
            <span>
              <a href='/tos'>Terms of Service</a>
            </span>
            <span>
              <a href='/privacy'>Privacy Policy</a>
            </span>
            <span>
              99barrels
              <span role='img' aria-label='trademark and copyright'>
                ™️ ©
              </span>
              2024
            </span>
          </div>
          <div id='footer-contact'>
            <h1>Contact Us</h1>
            <span>
              <Obfuscate
                email='info@99barrels.co'
                headers={{
                  subject: 'hello, 99b',
                }}
              />
            </span>
            <span>
              {' '}
              <Obfuscate tel='+1 415.786.6204' />
            </span>

            <span>www.99barrels.co</span>
          </div>
          <div id='footer-link'>
            {/* <h1>Links</h1>
                <span>Buy Wholesale</span>
                <span>Buy Retail</span>
                <span>Sign up/Log In</span> */}
          </div>
          <div id='footer-link'>
            {/* <h1>Social</h1>
                <span>Facebook</span>
                <span>Instagram</span>
                <span>Twitter</span>
                <span>Linked In</span> */}
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
