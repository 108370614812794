import React, { useState, useCallback, useEffect } from "react";
import { states } from "../../../../constants";
import "./FulfillmentPreferences.css";
import { RenderStates } from "./RenderStates";
export interface IFulfillmentPreferences {
  updateBusiness: Function;
  businessDetails: any;
}

export const FulfillmentPreferences: React.FunctionComponent<
  IFulfillmentPreferences
> = ({ updateBusiness, businessDetails }: IFulfillmentPreferences) => {
  //  const [shippingObject, setShippingObject] = useState({})
  //const appState = useContext(AppContext);
  const setBusinessDetailProperty = useCallback(
    (prop, newValue) => updateBusiness({ [prop]: { set: newValue } }),
    [updateBusiness]
  );
  const [rangeDistance, setRangeDistance] = useState(5);
  const [allowPickup, setAllowPickup] = useState(
    businessDetails.fulfillment_allow_pickup_retail ||
      businessDetails.fulfillment_allow_pickup_wholesale
  );
  const [doesShip, setDoesShip] = useState(false);
  const [doesDeliver, setDoesDeliver] = useState(false);
  const [deliver_retail_radius_miles, set_deliver_retail_radius_miles] =
    useState(businessDetails.fulfillment_deliver_retail_radius_miles);
  const [deliver_wholesale_radius_miles, set_deliver_wholesale_radius_miles] =
    useState(businessDetails.fulfillment_deliver_wholesale_radius_miles);
  useEffect(() => {
    setBusinessDetailProperty(
      "fulfillment_deliver_retail_radius_miles",
      deliver_retail_radius_miles
    );
  }, [deliver_retail_radius_miles]);
  useEffect(() => {
    setBusinessDetailProperty(
      "fulfillment_deliver_wholesale_radius_miles",
      deliver_wholesale_radius_miles
    );
  }, [deliver_wholesale_radius_miles]);

  //Ship
  //const [shipsUSA, setShipsUSA] = useState(false);
  return (
    <div id="fulfillment-page">
      <div id="fulfillment-preferences">
        <h1>Pickup, Delivery and Shipping</h1>
        <div id="pickup-container">
          <div className="horizontal-check">
            <input
              type="checkbox"
              id="allow-pickup"
              onClick={(e) => {
                //setBusinessDetailProperty('fulfillment_allow_pickup_retail', !allowPickup)
                setAllowPickup(!allowPickup);
              }}
              value={
                businessDetails.fulfillment_allow_pickup_retail ||
                businessDetails.fulfillment_allow_pickup_wholesale
              }
              checked={allowPickup}
              onChange={() => {}}
            />{" "}
            <label htmlFor="allow-pickup">
              <h1>Allow Pickup</h1>{" "}
            </label>
          </div>
          <p>Allow customers to pickup at your location.</p>
          {allowPickup && (
            <div className="horizontal-check">
              <div>
                <label htmlFor="allow-pickup-retail">
                  Allow Retail Customers to Pickup
                </label>
                <input
                  type="checkbox"
                  id="allow-pickup-retail"
                  checked={businessDetails.fulfillment_allow_pickup_retail}
                  onClick={(e) => {
                    setBusinessDetailProperty(
                      "fulfillment_allow_pickup_retail",
                      !businessDetails.fulfillment_allow_pickup_retail
                    );
                    //setAllowPickupRetail(!allowPickupRetail)
                  }}
                />
              </div>
              <div>
                <label htmlFor="allow-pickup-wholesale">
                  Allow Wholesale Customers to Pickup
                </label>
                <input
                  type="checkbox"
                  checked={businessDetails.fulfillment_allow_pickup_wholesale}
                  id="allow-pickup-wholesale"
                  onClick={(e) => {
                    setBusinessDetailProperty(
                      "fulfillment_allow_pickup_wholesale",
                      !businessDetails.fulfillment_allow_pickup_wholesale
                    );
                    //setAllowPickupRetail(!allowPickupRetail)
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div id="shipping-container">
          <div className="horizontal-check">
            <input
              disabled
              value={"doesShip"}
              type="checkbox"
              onChange={(e) => setDoesShip(!doesShip)}
              checked={doesShip}
              id="ship-products"
            />
            <label htmlFor="ship-products">
              <h1>Ship My Products - coming soon!</h1>
            </label>
          </div>
          {/* <label htmlFor="ship-products">
            <h1>Ship Retail</h1>
          </label>
          <input
            onClick={(e) => {
              setBusinessDetailProperty(
                "fulfillment_ship_retail",
                !businessDetails.fulfillment_ship_retail
              );
              //setAllowPickupRetail(!allowPickupRetail)
            }}
            type="checkbox"
            checked={businessDetails.fulfillment_ship_retail}
            id="ship-products-retail"
          />
          <label htmlFor="ship-products-wholesale">
            <h1>Ship Wholesale</h1>
          </label>
          <input
            onClick={(e) => {
              setBusinessDetailProperty(
                "fulfillment_ship_wholesale",
                !businessDetails.fulfillment_ship_wholesale
              );
            }}
            type="checkbox"
            checked={businessDetails.fulfillment_ship_wholesale}
            id="ship-products-wholesale"
          />

          <div className={doesShip ? "" : "hidden"}>
            <p>Which state(s) do you ship to?</p>
            <p>
              <b>Remember</b>: You and you alone are responsible for ensuring
              the legality of shipping your products to recipients. If you are
              in doubt, don't ship it.
            </p>
            <div>
              {RenderStates({ stateList: states, setBusinessDetailProperty })}
            </div>
            <div>
              <input
                disabled={true}
                type="checkbox"
                id="ship-internationally"
              />
              <label htmlFor="">Ship Internationally (coming soon!)</label>
            </div>
          </div> */}
        </div>
        <div id="deliver-container">
          <div className="horizontal-check">
            <input
              value={"deliver-products"}
              disabled
              onChange={(e) => setDoesDeliver(!doesDeliver)}
              checked={doesDeliver}
              type="checkbox"
              id="deliver-products"
            />
            <label htmlFor="deliver-products">
              <h1>Deliver My Products - coming soon! </h1>
            </label>
          </div>
          {doesDeliver ? (
            <div id="delivery-options">
              <div className="delivery-options">
                <div>
                  <input
                    type="checkbox"
                    id="deliver-retail"
                    checked={businessDetails.fulfillment_deliver_retail}
                    onClick={(e) => {
                      setBusinessDetailProperty(
                        "fulfillment_deliver_retail",
                        !businessDetails.fulfillment_deliver_retail
                      );
                    }}
                  />
                  <label htmlFor="deliver-retail">Deliver Retail</label>
                </div>
                {businessDetails.fulfillment_deliver_retail ? (
                  <>
                    <label htmlFor="deliver-retail-radius">
                      Distance (in miles) you'll deliver
                    </label>
                    {deliver_retail_radius_miles} miles
                    <input
                      type="range"
                      value={deliver_retail_radius_miles}
                      className="custom-range"
                      min="1"
                      max="100"
                      onChange={(event) =>
                        set_deliver_retail_radius_miles(
                          parseInt(event.target.value)
                        )
                      }
                    />
                  </>
                ) : null}
              </div>
              <div className="delivery-options">
                <div>
                  <input
                    type="checkbox"
                    checked={businessDetails.fulfillment_deliver_wholesale}
                    id="deliver-wholesale"
                    onClick={(e) => {
                      setBusinessDetailProperty(
                        "fulfillment_deliver_wholesale",
                        !businessDetails.fulfillment_deliver_wholesale
                      );
                    }}
                  />
                  <label htmlFor="deliver-wholesale">Deliver Wholesale</label>
                </div>
                {deliver_wholesale_radius_miles} miles
                <input
                  type="range"
                  value={deliver_wholesale_radius_miles}
                  className="custom-range"
                  min="1"
                  max="100"
                  onChange={(event) =>
                    set_deliver_wholesale_radius_miles(
                      parseInt(event.target.value)
                    )
                  }
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
