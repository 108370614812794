import { ApolloClient, InMemoryCache } from '@apollo/client'
import { useState, useEffect } from 'react'


const cache = new InMemoryCache(
    // {addTypename: false}
)
export const client = new ApolloClient(
    {
        cache,
        uri: process.env.REACT_APP_API_SERVER,
        //link: new HttpLink(),
        version: "1",
        credentials: 'include',   
    })

//The generic query that is passed to the apollo client, takes a GraphQL query,
//returns a GraphQL result 
export const useQuery = (query:any) => {
    const [results, setResults] = useState()
    useEffect(() => {
        const sub = client
            .subscribe({ query })
            .subscribe(result => setResults(result.data))
        return () => sub.unsubscribe()
    }, [query])
    return results
}

