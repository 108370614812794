//Contains all of the GQL Queries
import gql from 'graphql-tag';

export const GET_ME = gql`
  query GetMe {
    me {
      name
      is_admin
      email
      seesWholesalePricing
      works_at {
        name
        description
        license_info
        stripe_account_id
        id
        international_phone_number
        slug
        heroImgUrl
        website
        fulfillment_allow_pickup_retail
        fulfillment_allow_pickup_wholesale
        fulfillment_deliver_wholesale
        fulfillment_deliver_retail
        fulfillment_deliver_retail_radius_miles
        fulfillment_deliver_wholesale_radius_miles
        fulfillment_ship_retail
        fulfillment_ship_wholesale
        sales_tax_rate
        tags {
          id
          name
          categorizedBy {
            id
            name
          }
        }
        addresses {
          id
          street_number
          route
          country
          postal_code
          locality
          administrative_area_level_1
          administrative_area_level_2
        }
      }
    }
  }
`;

// export const sendEmail = gql`
//   query sendEmail($email: String, $body: String){
//     sendEmail(email: $email, body: $body)
//   }
// `

export const createTagQuery = (tagName, tagCategory) => {
  const q = `
  mutation{
    createTag(data:{
      name:"${tagName}",
      tagCategory:{
        connect:{name:"${tagCategory}"}
      }
    }){
      name
    }}
  `;
  return gql(q);
};

export const wholesalerFieldUpdate = (field, type = 'String') => {
  const q = `
    mutation UpdateWholesaler_${field} ($value: ${type}) {
      updateMyWholesaler(
        data: {
          ${field}: $value
        }
      ) {
      ${field}
      }
    }`;

  return gql(q);
};

export const getProducerQuery = (id) => {
  const q = `
   query {
	  businesses (where:{id:{equals:${id}}}){
          name
          id

      }
    }`;

  return gql(q);
};
