import { useQuery } from "./apollo";
import { gql } from "@apollo/client";
// export const useGetProducers = () => {
//   const producers = useQuery(getProducers)
//   return producers && producers.producers
// }

// export const useProducerProducts = () => {
//   // const products = useQuery(getProducerProducts())
//   //  return products
// }

// export const useGetProducer = (id) => {
//   const producer = useQuery(getProducerQuery(id))
//   return producer && producer.producer
// }

export const useGetBusinessBySlug = (slug) => {
  const business_query_data = useQuery(getProducerBySlugQuery(slug));
  return business_query_data && business_query_data.businesses[0];
};

export const getProducerBySlugQuery = (slug) => {
  const q = `
   query {
    businesses (where:{slug: {equals: "${slug}"}})
    {
          name
          id
          addresses {
            googlePlaceId
            formatted_address
            country
          }
 	        types
          website
          international_phone_number
          sales_tax_rate
          logoImgUrl
          heroImgUrl
          description
          instagram
          twitter
          linkedin
          facebook
      }
    }`;
  return gql(q);
};

export const tagBusinessWithTags = gql`
  mutation TagBusinessWithTags($businessId: Int!, $tags: [TagInputById!]) {
    TagBusinessWithTags(tags: $tags, businessId: $businessId)
  }
`;
