import React, { useEffect } from 'react';

import { BenefitTile } from './BenefitTile';
import './sell.css';

export default function Sell() {
  return (
    <div className='sell'>
      <div className='above-fold'>
        <div className={'split-row'}>
          <div>
            <h1>Why sell on 99barrels?</h1>
            <ul>
              <li>Drive Growth With A Global Market</li>
              <li>Save Money With No Hidden Fees</li>
              <li>Grow Your Wholesale + Retail Business</li>
              <li>Manage All Your Sales All In One Place</li>
              <li>Build Customer Loyalty </li>
              <li>Create Customized Marketing</li>
            </ul>
          </div>
          <div className={'bottles'}>
            <img alt='bottles' src='/images/sell/bottles.png' />
          </div>
        </div>
        <div className='benefit-tile-container'>
          <BenefitTile
            headline={'Create your custom marketplace page'}
            blurb={
              'Control your brand + interact with customers from one central business page.'
            }
          />
          <BenefitTile
            headline={'Add products + memberships'}
            blurb={
              'List your products, customize your pricing + packages, offer renewing orders + memberships.'
            }
          />
          <BenefitTile
            headline={'Market directly to your customers'}
            blurb={
              'Sell directly to your wholesale + retail customers. Easily create invites for others to join 99barrels.'
            }
          />
          <BenefitTile
            headline={'Save money with simple transactions'}
            blurb={`A simple ${process.env.REACT_APP_PERCENT}% transaction commission is the only fee you will pay on 99barrels. No other fees.`}
          />
        </div>
      </div>
      <div className='below-fold'>
        <h1>99barrels is here to help your business thrive </h1>
        <p>
          Use our integrated platform to reach more customers, increase sales,
          cut out needless expenses, and grow your business.
        </p>
        <p>With 99barrels you get:</p>
        <div class='benefits-list-container'>
          <ul>
            <li>Simple, easy to customize marketplace page</li>
            <li>
              {process.env.REACT_APP_PERCENT}% fee on transactions - no other
              hidden fees{' '}
            </li>
            <li>Customizable pricing for wholesale + retail sales</li>
            <li>Shipping, pick-up + delivery options set by you</li>
            <li>Renewal purchase options for all products </li>
          </ul>
          <ul>
            <li>Direct marketing to your customers </li>
            <li>Social media + website integration</li>
            <li>Club memberships + event ticket sales</li>
            <li>Large + growing customer marketplace</li>
            <li>Easy sign-up for wholesale customers</li>
          </ul>
        </div>
        <div>I'm ready to sell my products on 99barrels</div>
      </div>
    </div>
  );
}
