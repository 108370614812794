import React from 'react'
import SearchResultsTile from '../ProductTiles/SearchResultsProductTile/SearchResultsProductTile'
import { getDistance } from 'geolib';

export const ProductHits = ({ hits, isWholesaleBuyer, searchFulfillmentType, locationOfBuyer }) =>
  hits.map(hit => {
    let disabled = null
    if (searchFulfillmentType === "delivery") {
      const distanceInMeters = getDistance(
        { latitude: locationOfBuyer.lat, longitude: locationOfBuyer.lng },
        { latitude: hit._geoloc.lat, longitude: hit._geoloc.lng })        
      const distanceInMiles = distanceInMeters * 0.000621371192

      const allowedDistance =
        hit.fulfillment_deliver_wholesale_radius_miles ||
        hit.fulfillment_deliver_retail_radius_miles

      if (isWholesaleBuyer && !hit.fulfillment_deliver_wholesale)
        disabled = 'no-wholesale'
      else if (!isWholesaleBuyer && !hit.fulfilment_deliver_retail)
        disabled = 'no-retail'
      else if (distanceInMiles > allowedDistance)
        disabled = 'out-of-range'
    }

    return <SearchResultsTile disabled={disabled}
      key={hit.objectID} product={hit} isWholesaleBuyer={isWholesaleBuyer} />
  })
