import React, { useState, useCallback } from 'react';
import debounce from 'just-debounce-it';
import { useUpdateAddressMutation } from './Address.generated';

const Address = ({ address }) => {
  const [addressState, setAddressState] = useState(address);
  const [setAddressProperty] = useUpdateAddressMutation();

  const setBusinessAddressProperty = useCallback(
    (prop, newValue) =>
      setAddressProperty({
        variables: {
          data: { [prop]: { set: newValue } },
          addressId: address.id,
        },
      }),
    [setAddressProperty]
  );

  const debounceUpdateAddressField = useCallback(
    debounce((variable, value) => {
      setBusinessAddressProperty(variable, value);
    }, 500),
    [setBusinessAddressProperty]
  );

  function handleAddressFieldChange(variable, value) {
    setAddressState((prevState) => ({ ...prevState, [variable]: value }));
    debounceUpdateAddressField(variable, value);
  }
  return (
    <>
      <div>
        <label>Number</label>
        <input
          onChange={(e) => {
            handleAddressFieldChange('street_number', e.target.value);
          }}
          value={addressState.street_number || ''}
        />
      </div>
      <div>
        <label className='mt1 tl '>Street</label>
        <input
          onChange={(e) => {
            handleAddressFieldChange('route', e.target.value);
          }}
          value={addressState.route}
        />
      </div>
      <div>
        <div>
          <label>City</label>
          <input
            onChange={(e) => {
              handleAddressFieldChange('locality', e.target.value);
            }}
            className='mt1 input-custom'
            value={addressState.locality || ''}
          />
        </div>
        <div>
          <label>State</label>
          <input
            onChange={(e) => {
              handleAddressFieldChange(
                'administrative_area_level_1',
                e.target.value
              );
            }}
            className='mt1 input-custom'
            value={addressState.administrative_area_level_1}
          />
        </div>
      </div>

      <div>
        <label>Postal Code</label>
        <input
          onChange={(e) => {
            handleAddressFieldChange('postal_code', e.target.value);
          }}
          value={addressState.postal_code}
        />
      </div>
      <div>
        <label>Country</label>
        <input
          type='text'
          className='input-producer input-custom'
          onChange={(e) => {
            e.persist();
            handleAddressFieldChange('country', e.target.value);
          }}
          value={addressState.country}
        />
      </div>
    </>
  );
};
export default Address;
