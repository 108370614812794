import React, { useCallback, useState } from 'react';
import { DropzoneComponent } from 'react-dropzone-component';
import Address from '../Address/Address';
import debounce from 'just-debounce-it';
import './Profile.css';
import SocialPreferences from './SocialPreferences';
import { Business } from '../Business';

//styles for dropzone picker
require('../../../../node_modules/react-dropzone-component/styles/filepicker.css');
require('../../../../node_modules/dropzone/dist/min/dropzone.min.css');

import { BusinessProps } from '../Business';

const Profile: React.FC<BusinessProps> = ({ business, updateBusiness }) => {
  const [businessDetailsState, setBusinessDetailsState] =
    useState<Business | null>(business);

  const setBusinessDetailProperty = useCallback(
    (prop: keyof Business, newValue: any) =>
      updateBusiness({ [prop]: { set: newValue } }),
    [updateBusiness]
  );

  const heroImageConfig = {
    addRemoveLinks: true,
    dictRemoveFile: 'Remove',
    acceptedFiles: 'image/jpeg,image/png,image/gif',
    dictDefaultMessage: 'Drag or upload a banner image (.jpg, .png, .gif)',
    maxFiles: 1,
    thumbnailWidth: 60,
    thumbnailHeight: 60,
  };

  const dropzoneHeroEventHandlers = {
    success: (file: any, response: any) => {
      if (file.status === 'success') {
        setBusinessDetailProperty('heroImgUrl', response.fileUrl);
      }
    },
  };
  function debounceSetBusinessDetailProperty(
    prop: keyof Business,
    newValue: any
  ) {
    setBusinessDetailsState({
      ...(businessDetailsState as Business),
      [prop]: newValue,
    });
    debounce(() => setBusinessDetailProperty(prop, newValue), 500)();
  }

  const dropzoneComponentConfig = {
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_API_SERVER + '/upload', // route url to upload file over server
  };

  let heroImage;

  if (!business?.heroImgUrl) {
    heroImage = (
      <DropzoneComponent
        config={dropzoneComponentConfig}
        eventHandlers={dropzoneHeroEventHandlers}
        djsConfig={heroImageConfig}
      />
    );
  } else {
    heroImage = (
      <div>
        <img
          id='business-hero-image'
          src={businessDetailsState?.heroImgUrl as string}
          alt='Hero'
        />
        <div>
          Upload new Image
          <DropzoneComponent
            config={dropzoneComponentConfig}
            eventHandlers={dropzoneHeroEventHandlers}
            djsConfig={heroImageConfig}
          />
        </div>
      </div>
    );
  }

  if (!business) return <div>'Loading'</div>;

  return (
    <div className='edit-business content'>
      <div>
        <h2>Banner Image </h2>
        <p>
          This image will show at the top of your profile page. View your public
          profile at <br />{' '}
          <a
            target='_blank'
            id='profile-link'
            rel='noopener noreferrer'
            href={'/business/' + businessDetailsState?.slug}
          >
            {' '}
            {'https://www.99barrels.co/business/' + businessDetailsState?.slug}
            <img
              className='new-tab-icon'
              src='/icons/new_tab.png'
              alt='Open in new tab.'
            />
          </a>{' '}
        </p>
        {heroImage}
      </div>

      <div className='form-section'>
        <h2>Name and Address</h2>
        {formField(
          'Business Name',
          <input
            type='text'
            onChange={(e) => {
              // e.persist();

              debounceSetBusinessDetailProperty('name', e.target.value);
            }}
            value={businessDetailsState?.name}
          />
        )}

        {business.addresses.map((address, index) => {
          return <Address key={index} address={address} />;
        })}
        {formField(
          'Website',
          <input
            onChange={(e) => {
              //e.persist();
              debounceSetBusinessDetailProperty('website', e.target.value);
            }}
            value={businessDetailsState?.website as string}
          />
        )}

        {formField(
          'Phone Number',
          <input
            onChange={(e) => {
              debounceSetBusinessDetailProperty(
                'international_phone_number',
                e.target.value
              );
            }}
            value={businessDetailsState?.international_phone_number as string}
          />
        )}
        {formField(
          'Tax + Fees Percent Rate',
          <input
            type={'number'}
            onChange={(e) => {
              debounceSetBusinessDetailProperty(
                'sales_tax_rate',
                parseFloat(e.target.value)
              );
            }}
            value={businessDetailsState?.sales_tax_rate}
          />
        )}
      </div>
      <div>
        <textarea
          onChange={(e) => {
            // setBusinessDetailsState({
            //   ...businessDetailsState,
            //   description: e.target.value,
            // });
            debounceSetBusinessDetailProperty('description', e.target.value);
          }}
          value={businessDetailsState?.description || ''}
        />
        <label>Short Description</label>
      </div>
      <SocialPreferences
        setBusinessDetailProperty={setBusinessDetailProperty}
        businessDetails={business}
      />
    </div>
  );
};

export default Profile;

function formField(label: string, input: JSX.Element) {
  return (
    <div className='form-field'>
      <label>{label}</label>
      {input}
    </div>
  );
}
