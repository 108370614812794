import React, { useState, useEffect, useMemo } from 'react';
import AlgoliaContext from '../context/AlgoliaContext';
import algoliasearch from 'algoliasearch';
import {
  SEARCH_TOKEN_KEY,
  retrieveValue,
  storeValue,
} from '../dal/localStorageDAL';

export function AlgoliaProvider({ children }: { children: React.ReactNode }) {
  const [algoliaSearchToken, setAlgoliaSearchToken] = useState(
    retrieveValue(SEARCH_TOKEN_KEY)
  );
  useEffect(() => {
    //Get Search Token from local storage or fetch from server
    if (algoliaSearchToken) return;
    const requestOptions: RequestInit = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch(
      process.env.REACT_APP_API_SERVER + '/get-search-token',
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        const searchToken =
          responseJson.publicKey || process.env.REACT_APP_ALGOLIA_SEARCH_TOKEN!;
        storeValue(SEARCH_TOKEN_KEY, searchToken);
        setAlgoliaSearchToken(searchToken);
      })
      .catch(console.error);
  }, []);
  const client = useMemo(() => {
    if (!algoliaSearchToken) return undefined;
    return algoliasearch(
      process.env.REACT_APP_ALGOLIA_APPLICATION_ID!,
      algoliaSearchToken
    );
  }, [algoliaSearchToken]);

  return (
    <AlgoliaContext.Provider value={client}>{children}</AlgoliaContext.Provider>
  );
}
