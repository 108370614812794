import React from 'react';
// @ts-ignore TS7016
import Image from 'react-graceful-image';

interface BioProps {
  content: string;
  image: string;
}

const Bio: React.FC<BioProps> = ({ content, image }) => {
  return (
    <div className='bio'>
      <div className='bust'>
        <Image src={image} alt='Bio Image' />
      </div>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default Bio;
