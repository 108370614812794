import React, { useEffect, useState, useContext } from 'react';
import { InstantSearch, Pagination, useHits } from 'react-instantsearch';
import { Redirect, useLocation } from 'react-router-dom';
import Geocode from 'react-geocode';
import { ProductHits } from './ProductHits';
import AlgoliaContext from '../../context/AlgoliaContext';
import { Refiners } from './Refiners';
import useIsBusinessUser from '../../dal/user';
import './Search.css';

// From the API endpoint
export default function Search(props: any) {
  const client = useContext(AlgoliaContext);
  const [rangeDistance, setRangeDistance] = useState<number>(50);
  const [zipCode, setZipCode] = useState<string | undefined>();
  const [geoCode, setGeoCode] = useState<any>(null);
  const [searchFulfillmentType, setSearchFulfillmentType] =
    useState<string>('pickup');
  const [fulfillmentFilter, setFulfillmentFilter] = useState<string>(
    'fulfillment_allow_pickup_retail:true'
  );
  const isWholesaleBuyer = useIsBusinessUser();
  const location = useLocation();
  if (location.pathname === '/shop' && isWholesaleBuyer) {
    //Redirect
    return <Redirect to='/dashboard' />;
  }

  useEffect(() => {
    let filter = '';
    switch (searchFulfillmentType) {
      case 'pickup':
        filter = 'fulfillment_allow_pickup_';
        break;
      case 'delivery':
        filter = 'fulfillment_deliver_';
        break;
      case 'ship':
        filter = 'fulfillment_ship_';
        break;
    }
    if (isWholesaleBuyer) {
      filter += 'wholesale';
    } else {
      filter += 'retail';
    }
    setFulfillmentFilter(filter + ':true');
  }, [searchFulfillmentType, isWholesaleBuyer]);

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_UTILITY_API_KEY || '');
  Geocode.setLanguage('en');
  Geocode.setLocationType('ROOFTOP');

  useEffect(() => {
    if (!zipCode) return;
    Geocode.fromAddress(zipCode).then(
      (response: any) => {
        const addressComponents = response.results[0].address_components;
        const { lat, lng } = response.results[0].geometry.location;
        setGeoCode({ lat, lng });
      },
      (error: any) => {
        console.error('error getting geocode', error);
      }
    );
  }, [zipCode]);

  const Hits = connectHits(({ hits }: any) => {
    return (
      <ProductHits
        hits={hits}
        isWholesaleBuyer={isWholesaleBuyer}
        searchFulfillmentType={searchFulfillmentType}
        // rangeDistance={rangeDistance}
        locationOfBuyer={geoCode}
      />
    );
  });

  const instantSearchConfiguration = {
    filters: fulfillmentFilter,
    hitsPerPage: 8,
  };

  const onStateChange = ({ uiState, setUiState }: any) => {
    setUiState(uiState);
  };

  if (!client) return <div>Loading...</div>;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <InstantSearch
        future={{
          preserveSharedStateOnUnmount: false,
        }}
        indexName={process.env.REACT_APP_ALGOLIA_PRODUCTS_INDEX_NAME || ''}
        searchClient={client}
        onStateChange={onStateChange}
      >
        <div className='search content'></div>
        <div className='results-area'>
          <div className='search-refiners'>
            <div id={'fulfillment-choices'}>
              <h3>Fulfillment</h3>
              <label htmlFor='pickup'>
                <input
                  checked={searchFulfillmentType === 'pickup'}
                  value={'pickup'}
                  onChange={(e) => setSearchFulfillmentType(e.target.value)}
                  type={'radio'}
                  id='pickup'
                  style={{ fontSize: '.875rem' }}
                  name={'fulfillment-type'}
                />
                Pickup at business
              </label>
              {getFulfillmentOptions(
                searchFulfillmentType,
                zipCode,
                setZipCode,
                rangeDistance,
                setRangeDistance
              )}
            </div>
            {Refiners()}
            <p>
              You are currently shopping for{' '}
              <b>{isWholesaleBuyer ? 'wholesale' : 'retail'}</b> prices.
            </p>
          </div>
          <div className='search-results'>
            <Hits />
          </div>
        </div>
        <div className={'pagination'}>
          <Pagination />
        </div>
      </InstantSearch>
    </div>
  );
}

function isGeoLocationBased(fType: string) {
  if (fType === 'pickup' || fType === 'delivery') {
    return true;
  } else {
    return false;
  }
}

function getFulfillmentOptions(
  fType: string,
  zipCode: string | undefined,
  setZipCode: Function,
  rangeDistance: number,
  setRangeDistance: Function
) {
  switch (fType) {
    case 'pickup': {
      return (
        <>
          <input
            type='text'
            placeholder='Zip Code'
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
          Pickup distance from zip code:
          {rangeDistance} miles
          <input
            type='range'
            value={rangeDistance}
            className='custom-range'
            min='1'
            max='100'
            onChange={(event) => setRangeDistance(event.target.value)}
          />
        </>
      );
    }
    case 'delivery': {
      return (
        <>
          <input
            type='text'
            placeholder='Your delivery zip code'
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </>
      );
    }
    case 'ship': {
      return (
        <>
          <input
            type='text'
            placeholder='Your delivery zip Code'
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </>
      );
    }
    default:
      return null;
  }
}

function connectHits(Component: any) {
  const Hits = (props: any) => {
    const data = useHits(props);
    return <Component {...props} {...data} />;
  };
  return Hits;
}
