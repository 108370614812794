import React, { useState } from 'react';
import './social-preferences.css';
function SocialPreferences({ setBusinessDetailProperty, businessDetails }) {
  // const appContext = useContext(AppContext)
  // const { instagram, twitter, facebook, linkedin } = appContext.user.works_at
  const [instagramState, setInstagramState] = useState(
    businessDetails.instagram
  );
  const [twitterState, setTwitterState] = useState(businessDetails.twitter);
  const [facebookState, setFacebookState] = useState(businessDetails.facebook);
  const [linkedinState, setLinkedinState] = useState(businessDetails.linkedin);

  const onChangeInput = (e, type) => {
    const value = e.target.value;
    switch (type) {
      case 'instagram':
        setInstagramState(value);
        setBusinessDetailProperty('instagram', value);
        break;
      case 'twitter':
        setTwitterState(value);
        setBusinessDetailProperty('twitter', value);
        break;
      case 'facebook':
        setFacebookState(value);
        setBusinessDetailProperty('facebook', value);
        break;
      case 'linkedin':
        setLinkedinState(value);
        setBusinessDetailProperty('linkedin', value);
        break;
      default:
        return null;
    }
  };

  return (
    <div className={'social-preferences'}>
      <h2>Social Media</h2>
      <span>
        Input your various social media handles in these boxes and they'll show
        up on your profile.
      </span>
      <div>
        <img
          width='30px'
          height='30px'
          src='/icons/svg/instagram.svg'
          alt='Instagram'
        />
        <input
          value={businessDetails.instagram}
          onChange={(e) => onChangeInput(e, 'instagram')}
          placeholder=''
        />
        {instagramState ? (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={'https://www.instagram.com/' + instagramState}
          >
            Check
          </a>
        ) : (
          ''
        )}
      </div>

      <div>
        <img
          width='30px'
          height='30px'
          src='/icons/svg/twitter.svg'
          alt='Twitter'
        />
        <input
          value={twitterState}
          onChange={(e) => onChangeInput(e, 'twitter')}
          placeholder=''
        />
        {twitterState ? (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={'https://www.twitter.com/' + twitterState}
          >
            Check
          </a>
        ) : (
          ''
        )}
      </div>

      <div>
        <img
          width='30px'
          height='30px'
          src='/icons/svg/facebook.svg'
          alt='Facebok'
        />
        <input
          value={facebookState}
          onChange={(e) => onChangeInput(e, 'facebook')}
          placeholder=''
        />
        {facebookState ? (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={'https://www.facebook.com/' + facebookState}
          >
            Check
          </a>
        ) : (
          ''
        )}
      </div>

      <div>
        <img
          width='30px'
          height='30px'
          src='/icons/svg/linkedin.svg'
          alt='Linkedin'
        />
        <input
          value={linkedinState}
          onChange={(e) => onChangeInput(e, 'linkedin')}
          placeholder=''
        />
        {linkedinState ? (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={'https://www.linkedin.com/company/' + linkedinState}
          >
            Check
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default SocialPreferences;
