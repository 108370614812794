import React, {
  ComponentType,
  Profiler,
  useCallback,
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
} from 'react';
import { useParams } from 'react-router';
import CreatableSelect from 'react-select/creatable';
import {
  useGetBusinessFromSlugQuery,
  GetBusinessFromSlugQuery,
  GetBusinessFromSlugDocument,
  useUpdateBusinessMutation,
  UpdateBusinessMutation,
} from './Business.generated';
import './Business.css';
import { useGetTagsForCategoryByCategoryNameQuery } from '../../dal/tags.generated';
import * as Profile from './Profile';

export type Business = GetBusinessFromSlugQuery['businesses'][0];

export function businessRoute<P extends BusinessProps>(
  Component: ComponentType<P>
): ComponentType {
  function Inner(props: P) {
    const { slug } = useParams() as any;
    const { data, loading, error } = useGetBusinessFromSlugQuery({
      variables: { slug },
    });
    const [updateBusinessMut] = useUpdateBusinessMutation();
    type Timer = ReturnType<typeof setTimeout> | null;
    const timeRef = useRef<Timer>(null);
    const updateBusiness = (update: BusinessUpdateInput) => {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
      const timer = setTimeout(() => {
        updateBusinessMut({ variables: { slug, update } });
      }, 1000);
      timeRef.current = timer;
    };

    if (loading) return <pre>loading</pre>;
    if (error) return <pre>{error}</pre>;
    const business = data?.businesses[0];
    if (!business) return <pre>no business here</pre>;

    return (
      <Component
        {...props}
        business={business}
        updateBusiness={updateBusiness}
      />
    );
  }
  Object.defineProperty(Inner, 'name', {
    value: `BusinessRoute(Component.name)`,
  });
  return Inner as ComponentType;
}

export interface BusinessProps {
  business: Business;
  updateBusiness(
    update: BusinessUpdateInput
  ): Promise<FetchResult<UpdateBusinessMutation>>;
}

import { useTagBusinessWithTagsMutation } from './Business.generated';
import { ValueType } from 'react-select';
import { BusinessUpdateInput } from '../../generated/types';
import { FetchResult } from '@apollo/client';

function Edit(props: BusinessProps) {
  useEffect(() => {
    const anchor = window.location.hash.slice(1);
    if (anchor) {
      console.log('anchor', anchor);
      const anchorEl = document.getElementById(anchor);
      console.log('anchorEl', anchorEl);
      if (anchorEl) {
        console.log('scroll');
        anchorEl.scrollIntoView();
      }
    }
  });
  const { business, updateBusiness } = props;
  const businessTypes = useMemo(
    () =>
      business.types.map((t) => ({
        value: t.id,
        label: t.name,
        categorizedBy: t.categorizedBy,
      })),
    [business]
  );

  const { loading, data: businessTags } =
    useGetTagsForCategoryByCategoryNameQuery({
      variables: { tagCategoryName: 'Business Type' },
    });
  const businessTypeTagId = businessTags?.TagsForCategory[0].categorizedBy.id;

  const [tagBusinessWithTags] = useTagBusinessWithTagsMutation();
  // const onChangex = useCallback((values: ValueType<{
  //   value: number
  //   label: string
  //   categorizedBy: { id: number }
  //   __isNew__?: boolean
  // }, true>) => {
  //   tagBusinessWithTags({
  //     update(cache) {
  //       cache.writeQuery({
  //         query: GetBusinessFromSlugDocument,
  //         variables: { slug: business.slug },
  //         data: {
  //           businesses: [{
  //             ...business,
  //             types: values?.map(v => ({ id: v.value, name: v.label, categorizedBy: v.categorizedBy })) ?? []
  //           }]
  //         }
  //       })
  //     },
  //     variables: {
  //       businessId: business.id,
  //       tags: values?.map(x => {
  //         if (!x.__isNew__) {
  //           return { id: x.value, name: x.label, categorizedById: businessTypeTagId }
  //         }
  //         else { //Is new
  //           return { id: -1, name: x.label, isNew: true, categorizedById: businessTypeTagId }
  //         }
  //       })
  //     }
  //   })
  // }, [tagBusinessWithTags, businessTypeTagId])

  const onChange = useCallback(
    (
      values: ValueType<
        {
          value: number;
          label: string;
          categorizedBy: { id: number };
          __isNew__?: boolean;
        },
        true
      >
    ) => {
      //How do we debounce this, so it only happens after the user has stopped typing?
      updateBusiness({
        tags: {
          set:
            values?.map((v) => ({
              id: v.value,
              name: v.label,
              categorizedBy: v.categorizedBy,
            })) ?? [],
        },
      });
    },
    [updateBusiness]
  );

  if (loading) return <pre>loading</pre>;
  const tags = businessTags?.TagsForCategory;

  return (
    <div className='content'>
      <h1>Edit Profile</h1>
      {/* <CreatableSelect
        options={tags?.map((tag) => {
          return { ...tag, value: tag.id, label: tag.name };
        })}
        className="react-select-container"
        classNamePrefix="react-select"
        autosize={true}
        isMulti={true}
        backspaceRemovesValue
        value={businessTypes}
        onChange={onChange}
      /> */}
      <Profile.Profile updateBusiness={updateBusiness} business={business} />
      <Profile.FulfillmentPreferences
        updateBusiness={updateBusiness}
        businessDetails={business}
      />
      <a id='payment'></a>
      <Profile.PaymentPreferences />
    </div>
  );
}

export const EditBusiness = businessRoute(Edit);
