import * as Types from '../../../generated/types';

import { gql } from '@apollo/client';
import { FullAddressFragmentDoc } from './Address/Address.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBusinessFromSlugQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
}>;

export type GetBusinessFromSlugQuery = {
  __typename?: 'Query';
  businesses: Array<{
    __typename?: 'Business';
    id: number;
    name: string;
    slug?: string | null;
    instagram?: string | null;
    description: string;
    license_info?: string | null;
    international_phone_number?: string | null;
    heroImgUrl?: string | null;
    website?: string | null;
    fulfillment_allow_pickup_retail: boolean;
    fulfillment_deliver_retail_radius_miles: number;
    fulfillment_allow_pickup_wholesale: boolean;
    fulfillment_deliver_wholesale: boolean;
    fulfillment_deliver_retail: boolean;
    fulfillment_ship_retail: boolean;
    fulfillment_ship_wholesale: boolean;
    sales_tax_rate?: number;
    addresses: Array<{
      __typename?: 'Address';
      id: string;
      street_number?: string | null;
      route?: string | null;
      country?: string | null;
      postal_code?: string | null;
      locality?: string | null;
      administrative_area_level_1?: string | null;
      administrative_area_level_2?: string | null;
    }>;
    types: Array<{
      __typename?: 'Tag';
      id: number;
      name: string;
      categorizedBy: { __typename?: 'Tag'; id: number };
    }>;
  }>;
};

export type BusinessWithTypesFragment = {
  __typename?: 'Business';
  id: number;
  name: string;
  slug?: string | null;
  description: string;
  license_info?: string | null;
  international_phone_number?: string | null;
  heroImgUrl?: string | null;
  website?: string | null;
  fulfillment_allow_pickup_retail: boolean;
  fulfillment_allow_pickup_wholesale: boolean;
  fulfillment_deliver_retail_radius_miles: number;
  fulfillment_deliver_wholesale: boolean;
  fulfillment_deliver_retail: boolean;
  fulfillment_ship_retail: boolean;
  fulfillment_ship_wholesale: boolean;
  sales_tax_rate?: number;
  addresses: Array<{
    __typename?: 'Address';
    id: string;
    street_number?: string | null;
    route?: string | null;
    country?: string | null;
    postal_code?: string | null;
    locality?: string | null;
    administrative_area_level_1?: string | null;
    administrative_area_level_2?: string | null;
  }>;
  types: Array<{
    __typename?: 'Tag';
    id: number;
    name: string;
    categorizedBy: { __typename?: 'Tag'; id: number };
  }>;
};

export type TagBusinessWithTagsMutationVariables = Types.Exact<{
  businessId: Types.Scalars['Int'];
  tags?: Types.InputMaybe<Array<Types.TagInputById> | Types.TagInputById>;
}>;

export type TagBusinessWithTagsMutation = {
  __typename?: 'Mutation';
  TagBusinessWithTags: boolean;
};

export type UpdateBusinessMutationVariables = Types.Exact<{
  slug: Types.Scalars['String'];
  update: Types.BusinessUpdateInput;
}>;

export type UpdateBusinessMutation = {
  __typename?: 'Mutation';
  updateOneBusiness?: {
    __typename?: 'Business';
    id: number;
    name: string;
    slug?: string | null;
    description: string;
    twitter?: string | null;
    instagram?: string | null;
    facebook?: string | null;
    license_info?: string | null;
    international_phone_number?: string | null;
    heroImgUrl?: string | null;
    website?: string | null;
    fulfillment_allow_pickup_retail: boolean;
    fulfillment_allow_pickup_wholesale: boolean;
    fulfillment_deliver_wholesale: boolean;
    fulfillment_deliver_retail: boolean;
    fulfillment_ship_retail: boolean;
    fulfillment_ship_wholesale: boolean;
    addresses: Array<{
      __typename?: 'Address';
      id: string;
      street_number?: string | null;
      route?: string | null;
      country?: string | null;
      postal_code?: string | null;
      locality?: string | null;
      administrative_area_level_1?: string | null;
      administrative_area_level_2?: string | null;
    }>;
    types: Array<{
      __typename?: 'Tag';
      id: number;
      name: string;
      categorizedBy: { __typename?: 'Tag'; id: number };
    }>;
  } | null;
};

export const BusinessWithTypesFragmentDoc = gql`
  fragment businessWithTypes on Business {
    id
    name
    slug
    name
    description
    license_info
    id
    international_phone_number
    slug
    heroImgUrl
    twitter
    instagram
    facebook
    linkedin
    website
    fulfillment_allow_pickup_retail
    fulfillment_allow_pickup_wholesale
    fulfillment_deliver_wholesale
    fulfillment_deliver_retail
    fulfillment_ship_retail
    fulfillment_ship_wholesale
    fulfillment_deliver_retail_radius_miles
    fulfillment_deliver_wholesale_radius_miles
    sales_tax_rate
    addresses {
      ...fullAddress
    }
    types: businessTypes {
      id
      name
      categorizedBy {
        id
      }
    }
  }
  ${FullAddressFragmentDoc}
`;
export const GetBusinessFromSlugDocument = gql`
  query GetBusinessFromSlug($slug: String!) {
    businesses(where: { slug: { equals: $slug } }) {
      ...businessWithTypes
    }
  }
  ${BusinessWithTypesFragmentDoc}
`;

/**
 * __useGetBusinessFromSlugQuery__
 *
 * To run a query within a React component, call `useGetBusinessFromSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessFromSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessFromSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetBusinessFromSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBusinessFromSlugQuery,
    GetBusinessFromSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBusinessFromSlugQuery,
    GetBusinessFromSlugQueryVariables
  >(GetBusinessFromSlugDocument, options);
}
export function useGetBusinessFromSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessFromSlugQuery,
    GetBusinessFromSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBusinessFromSlugQuery,
    GetBusinessFromSlugQueryVariables
  >(GetBusinessFromSlugDocument, options);
}
export type GetBusinessFromSlugQueryHookResult = ReturnType<
  typeof useGetBusinessFromSlugQuery
>;
export type GetBusinessFromSlugLazyQueryHookResult = ReturnType<
  typeof useGetBusinessFromSlugLazyQuery
>;
export type GetBusinessFromSlugQueryResult = Apollo.QueryResult<
  GetBusinessFromSlugQuery,
  GetBusinessFromSlugQueryVariables
>;
export const TagBusinessWithTagsDocument = gql`
  mutation TagBusinessWithTags($businessId: Int!, $tags: [TagInputById!]) {
    TagBusinessWithTags(tags: $tags, businessId: $businessId)
  }
`;
export type TagBusinessWithTagsMutationFn = Apollo.MutationFunction<
  TagBusinessWithTagsMutation,
  TagBusinessWithTagsMutationVariables
>;

/**
 * __useTagBusinessWithTagsMutation__
 *
 * To run a mutation, you first call `useTagBusinessWithTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagBusinessWithTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagBusinessWithTagsMutation, { data, loading, error }] = useTagBusinessWithTagsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useTagBusinessWithTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TagBusinessWithTagsMutation,
    TagBusinessWithTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TagBusinessWithTagsMutation,
    TagBusinessWithTagsMutationVariables
  >(TagBusinessWithTagsDocument, options);
}
export type TagBusinessWithTagsMutationHookResult = ReturnType<
  typeof useTagBusinessWithTagsMutation
>;
export type TagBusinessWithTagsMutationResult =
  Apollo.MutationResult<TagBusinessWithTagsMutation>;
export type TagBusinessWithTagsMutationOptions = Apollo.BaseMutationOptions<
  TagBusinessWithTagsMutation,
  TagBusinessWithTagsMutationVariables
>;
export const UpdateBusinessDocument = gql`
  mutation UpdateBusiness($slug: String!, $update: BusinessUpdateInput!) {
    updateOneBusiness(where: { slug: $slug }, data: $update) {
      ...businessWithTypes
    }
  }
  ${BusinessWithTypesFragmentDoc}
`;
export type UpdateBusinessMutationFn = Apollo.MutationFunction<
  UpdateBusinessMutation,
  UpdateBusinessMutationVariables
>;

/**
 * __useUpdateBusinessMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessMutation, { data, loading, error }] = useUpdateBusinessMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBusinessMutation,
    UpdateBusinessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBusinessMutation,
    UpdateBusinessMutationVariables
  >(UpdateBusinessDocument, options);
}
export type UpdateBusinessMutationHookResult = ReturnType<
  typeof useUpdateBusinessMutation
>;
export type UpdateBusinessMutationResult =
  Apollo.MutationResult<UpdateBusinessMutation>;
export type UpdateBusinessMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessMutation,
  UpdateBusinessMutationVariables
>;
