import React, { useState } from 'react';
import { useGetBusinessBySlug } from '../../../dal/business';
import SearchResultsTile from '../../ProductTiles/SearchResultsProductTile/SearchResultsProductTile';
import { useParams } from 'react-router-dom';
import algoliasearch from 'algoliasearch';

import {
  InstantSearch /*, SortBy*/,
  ClearRefinements,
  Configure,
  RefinementList,
  useHits,
} from 'react-instantsearch';

import './PublicProfile.css';
const ProductHits = ({ hits }) => {
  return (
    <>
      {hits.map((hit, index) => {
        let product = { ...hit };
        return <SearchResultsTile key={index} product={product} />;
      })}
    </>
  );
};

const Hits = connectHits(ProductHits);
function ProducerPublic(props) {
  const [algoliaSearchToken] = useState(
    process.env.REACT_APP_ALGOLIA_CONSUMER_SEARCH_ONLY_API_KEY
  );
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    algoliaSearchToken
  );
  const refiners = ['Beverage Type', 'ABV', 'Varietal', 'Closure Style'];
  let { slug } = useParams();
  const producer = useGetBusinessBySlug(slug);

  if (!producer) {
    return '';
  }

  return (
    <div className='content'>
      {producer.heroImgUrl ? (
        <div className='banner-container'>
          <img
            alt='banner img for this business'
            src={producer.heroImgUrl ? producer.heroImgUrl : ''}
          />
        </div>
      ) : (
        ''
      )}

      <div>
        <div className={'business-header'}>
          <div>
            <h1>{producer.name}</h1>
          </div>
          <div className='social-icons'>
            {producer.instagram && (
              <a
                href={`https://www.instagram.com/${producer.instagram}`}
                target='_blank'
                rel='noreferrer noopener'
                className='flex ml3'
              >
                <img
                  alt='instagram logo'
                  width='35px'
                  height='35px'
                  src={`/icons/svg/instagram.svg`}
                />
              </a>
            )}
            {producer.twitter && (
              <a
                href={`https://www.twitter.com/${producer.twitter}`}
                target='_blank'
                rel='noreferrer noopener'
                className='flex ml3'
              >
                <img
                  alt='twitter logo'
                  width='35px'
                  height='35px'
                  src={`/icons/svg/twitter.svg`}
                />
              </a>
            )}
            {producer.facebook && (
              <a
                href={`https://www.facebook.com/${producer.facebook}`}
                target='_blank'
                rel='noreferrer noopener'
                className='flex ml3'
              >
                <img
                  alt='facebook logo'
                  width='35px'
                  height='35px'
                  src={`/icons/svg/facebook.svg`}
                />
              </a>
            )}
            {producer.linkedin && (
              <a
                href={`https://www.linkedin.com/company/${producer.linkedin}`}
                target='_blank'
                rel='noreferrer noopener'
                className='flex ml3'
              >
                <img
                  alt='linkedin logo'
                  width='35px'
                  height='35px'
                  src={`/icons/svg/linkedin.svg`}
                />
              </a>
            )}
          </div>
        </div>
        <div className='business-details'>
          <div>{producer.description}</div>
          <div className='business-website-phone'>
            <div>
              <a
                target='_blank'
                rel='noreferrer noopener'
                href={producer.website}
                aria='Producer Website'
              >
                {' '}
                {producer.website}{' '}
                <img
                  src='/icons/new_tab.png'
                  alt='Open in a new tab icon'
                  className='new-tab-icon'
                />{' '}
              </a>
            </div>
            <span>
              <a href={'tel:' + producer.international_phone_number}>
                {producer.international_phone_number}
              </a>
            </span>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <InstantSearch
            indexName={process.env.REACT_APP_ALGOLIA_PRODUCTS_INDEX_NAME}
            apiKey={algoliaSearchToken}
            searchClient={client}
            className='business-products'
          >
            <div className='search-refiners'>
              <ClearRefinements />
              {refiners.map((refiner) => (
                <div key={refiner}>
                  <h3>{refiner}</h3>
                  <RefinementList attribute={refiner} />
                </div>
              ))}
            </div>
            <div className='search-results'>
              <Configure
                filters={'slug:' + slug}
                hitsPerPage={4}
                analytics={false}
                //enablePersonalization={true}
                distinct
              />
              <Hits />
            </div>
          </InstantSearch>
        </div>
      </div>
    </div>
  );
}

export default ProducerPublic;

// TODO (Codemod generated): ensure your usage correctly maps the props from the connector to the hook
function connectHits(Component) {
  const Hits = (props) => {
    const data = useHits(props);

    return <Component {...props} {...data} />;
  };

  return Hits;
}
