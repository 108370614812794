// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState, useContext } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import React from 'react';
import { useSidebarContext } from '../../context/sidebarContext';
import AppContext from '../../context/AppContext';
import './our-sidebar.css';
import { useUser } from '../../dal/user';
import { BusinessNavigation } from '../Business/BusinessNavigation/BusinessNavigation';
// const colorList = {
//   'BUSINESS': 'bg-business',
//   'CONSUMER': 'bg-consumer',
//   'Guest': 'bg-guest'
// }
// const chooseColor = (role) => {
//   return role ? colorList[role] : 'bg-guest'
// }
// const { user } = context?.appState
// const role = user?.roles ? user.roles[0] : "Guest"
// const color = chooseColor(role);

const Item = ({ title, to, icon, selected, id, setSelected }) => {
  return (
    <MenuItem
      id={id}
      active={selected === title}
      style={{ fontSize: '1.4rem' }}
      onClick={() => setSelected(title)}
      component={<Link to={to} />}
    >
      {title}
    </MenuItem>
  );
};

const logout = () => {
  localStorage.clear();
  location.pathname = '/';
};

const OurSidebar = () => {
  const businesses = useUser()?.works_at;
  const [selected, setSelected] = useState('Dashboard');
  const { sidebarRTL, sidebarImage, collapsed } = useSidebarContext();

  function BusinessesForSidebar() {
    if (!businesses) return null;
    let content;
    if (!businesses.length) {
      content = '';
    } else {
      content = businesses.map((business, index) => (
        <React.Fragment key={index}>
          <Item
            key={index}
            title={business.name}
            to={`/business/${business.slug}`}
            selected={selected}
            setSelected={setSelected}
          />
          <BusinessNavigation slug={business.slug} />
        </React.Fragment>
      ));
    }
    return content;
  }

  return (
    <div id='our-sidebar'>
      <Sidebar
        rootStyles={
          {
            // [`.${sidebarClasses?.container}`]: {
            //   backgroundColor: 'red',
            // },
          }
        }
        breakPoint='md'
        style={{ height: '100%', fontSize: '1.2rem' }}
        rtl={sidebarRTL}
        image={sidebarImage}
      >
        <Menu>
          {!collapsed && (
            <div style={{ marginBottom: '25px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '15px',
                  marginLeft: '15px',
                }}
              >
                <img
                  className='our-sidebar-logo-image'
                  alt='99barrels logo'
                  src={'/images/barrel-black.png'}
                  style={{ width: '2rem', cursor: 'pointer' }}
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h3>99barrels</h3>
              </div>
            </div>
          )}
          <div style={{ paddingLeft: collapsed ? undefined : '0%' }}>
            <Item
              title='Dashboard'
              to='/dashboard'
              selected={selected}
              id='our-sidebar-dashboard'
              setSelected={setSelected}
            />
            <Item
              title='Orders'
              to='/business/orders'
              selected={selected}
              id='our-sidebar-orders'
              setSelected={setSelected}
            />
            <BusinessesForSidebar />

            <Link
              className='button'
              id='add-new-business-button'
              to='/business/new'
              width='10rem'
            >
              add new business
            </Link>
          </div>
          <Item
            title='Shop / Research'
            to='/business/research-and-shop'
            selected={selected}
            setSelected={setSelected}
          />
          <div id='logout-container'>
            <a
              className='button'
              id='logout-link'
              href={process.env.REACT_APP_API_SERVER + '/logout'}
              onClick={logout}
            >
              Logout
            </a>
          </div>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default OurSidebar;

/* <MenuItem
            icon={
              collapsed ? (
                <a onClick={() => collapseSidebar()}>UNTOGGLE</a>
              ) : sidebarRTL ? (
                <a
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              ) : (
                <a
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              )
            }
            style={{
              margin: "10px 0 20px 0",

            }}
          >
            {!collapsed && (
              <div
                style={{display:'flex', 
                justifyContent:'center',
                alignItems:'center',
                }}
              >
                <a
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >TOGGLE</a>
               
              </div>
            )}
          </MenuItem> */
