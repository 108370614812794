import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import './BusinessNavigation.css';
type BusinessParams = {
  slug: string;
};

export function BusinessNavigation({
  className,
  slug,
}: {
  className?: string;
  slug?: string;
}) {
  // slug = slug ?? useParams<BusinessParams>();
  return (
    <div id='business-navigation' className={className}>
      <ul>
        <li>
          <Link to={`/business/${slug}/products`}>Manage Products</Link>
        </li>
        <li>
          <Link to={`/business/${slug}/products/new`}>Add Product</Link>
        </li>
        <li>
          <Link to={`/business/${slug}/edit`}>Edit Public Profile</Link>
        </li>
      </ul>
    </div>
  );
}
