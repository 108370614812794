import React from 'react';
import { ClearRefinements } from 'react-instantsearch';
import SearchRefiner from './SearchRefiner';

export function Refiners() {
  return (
    <div className='search-refiners'>
      <SearchRefiner attribute={'Beverage Type'} />
      <SearchRefiner attribute={'Closure Style'} />
      <SearchRefiner attribute={'Format'} />
      <SearchRefiner attribute={'Produced By'} searchable={true} />
      <SearchRefiner attribute={'Varietal'} searchable={true} />
      <SearchRefiner attribute={'Vintage'} searchable={true} />
      <SearchRefiner attribute={'Volume (in ml)'} />
      <ClearRefinements />
    </div>
  );
}
