import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTagsForCategoryByCategoryNameQueryVariables = Types.Exact<{
  tagCategoryName: Types.Scalars['String'];
}>;


export type GetTagsForCategoryByCategoryNameQuery = { __typename?: 'Query', TagsForCategory: Array<{ __typename?: 'Tag', name: string, id: number, categorizedBy: { __typename?: 'Tag', id: number }, categorizes: Array<{ __typename?: 'Tag', id: number, name: string }> }> };


export const GetTagsForCategoryByCategoryNameDocument = gql`
    query GetTagsForCategoryByCategoryName($tagCategoryName: String!) {
  TagsForCategory(tagCategory: $tagCategoryName) {
    categorizedBy {
      id
    }
    name
    id
    categorizes {
      id
      name
    }
  }
}
    `;

/**
 * __useGetTagsForCategoryByCategoryNameQuery__
 *
 * To run a query within a React component, call `useGetTagsForCategoryByCategoryNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsForCategoryByCategoryNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsForCategoryByCategoryNameQuery({
 *   variables: {
 *      tagCategoryName: // value for 'tagCategoryName'
 *   },
 * });
 */
export function useGetTagsForCategoryByCategoryNameQuery(baseOptions: Apollo.QueryHookOptions<GetTagsForCategoryByCategoryNameQuery, GetTagsForCategoryByCategoryNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsForCategoryByCategoryNameQuery, GetTagsForCategoryByCategoryNameQueryVariables>(GetTagsForCategoryByCategoryNameDocument, options);
      }
export function useGetTagsForCategoryByCategoryNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsForCategoryByCategoryNameQuery, GetTagsForCategoryByCategoryNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsForCategoryByCategoryNameQuery, GetTagsForCategoryByCategoryNameQueryVariables>(GetTagsForCategoryByCategoryNameDocument, options);
        }
export type GetTagsForCategoryByCategoryNameQueryHookResult = ReturnType<typeof useGetTagsForCategoryByCategoryNameQuery>;
export type GetTagsForCategoryByCategoryNameLazyQueryHookResult = ReturnType<typeof useGetTagsForCategoryByCategoryNameLazyQuery>;
export type GetTagsForCategoryByCategoryNameQueryResult = Apollo.QueryResult<GetTagsForCategoryByCategoryNameQuery, GetTagsForCategoryByCategoryNameQueryVariables>;