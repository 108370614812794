import { useState, useEffect, useContext } from 'react'
import { client, useQuery } from './apollo'
import { GET_ME } from './gql-queries'
import gql from 'graphql-tag'
import AppContext from '../context/AppContext'

const UPSERT_A_WORKPLACE = gql`
mutation ($works_at:CreateBusinessInput!){
UpsertWorkplace(works_at:$works_at) 
}`

export default function useIsBusinessUser() {
  const { appState } = useContext(AppContext);
  const { user } = appState;
  return user && user.works_at.length > 0;
}

//TODO remove ID from all of these queries - get it from saved session on server
export const updateMyUserWithNewBusiness = (works_at: any) => {
  return new Promise((resolve, reject) => {
    client.mutate({
      mutation: UPSERT_A_WORKPLACE,
      variables: {
        works_at: works_at
      }
    }).then((data) => { resolve(data) }).catch((error) => {
      reject(error)
    })
  })
}

import { GetMeQuery } from '../../graphql/generated'

export const useUser = () => {
  const [user, setUser] = useState<GetMeQuery["me"] | null | undefined>()
  useEffect(() => {
    client
      .watchQuery({ query: GET_ME }).subscribe(results => {
        let user = results.data && results.data.me;
        //if (profile && profile.commercePreferences) { profile.commercePreferences = {} }
        ;(window as any)._USER = user
        setUser(user)
      })
  }, [])

  return user
}
