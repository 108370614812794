import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import React, { Suspense, lazy, useEffect } from 'react';
import Search from './components/Search';
import Sell from './components/Sell';
import About from './pages/About';
import AppProviders from './providers/AppProviders';
import ProducerPublic from './components/Business/Profile/PublicProfile';
import TOS from './pages/tos';
import Privacy from './pages/privacy';
import { Toaster } from 'react-hot-toast';
import Footer from './components/Footer';
const RegisterBusiness = lazy(() =>
  import('./components/Business/RegisterBusiness')
);
import { EditBusiness } from './components/Business/Business';
import OurSidebar from './components/OurSidebar/OurSidebar';
const Main = lazy(() => import('./components/Main'));
const Header = lazy(() => import('./components/Header'));
const CreateProduct = lazy(() =>
  import('./components/Product/ProductForm/ProductForm')
);
const MyProducts = lazy(() => import('./components/Product/MyProducts'));
const EditProducts = lazy(() => import('./components/Product/EditProducts'));
//const EditProfile = lazy(() => import('./components/Business/EditProfile'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Consumer = lazy(() => import('./components/Consumer'));
const ShoppingCart = lazy(() => import('./components/Commerce/'));
const ShippingAndBilling = lazy(() =>
  import('./components/Commerce/ShippingAndBilling/ShippingAndBilling')
);
const Orders = lazy(() => import('./components/Business/Orders'));
const CheckoutSuccess = lazy(() =>
  import('./components/Commerce/CheckoutSuccess')
);
const StripeConnectStatus = lazy(() =>
  import('./components/Business/Stripe/StripeConnectStatus')
);

export const App = () => {
  return (
    <AppProviders>
      <Router>
        <Suspense fallback={<div></div>}>
          <Main>
            <React.Fragment>
              <Route component={Header} />
              <Toaster />
              <Route exact path='/'>
                <Redirect to='/shop' />
              </Route>
              <Route exact path='/tos' component={TOS} />
              <Route exact path='/privacy' component={Privacy} />
              <Route path='/sell' component={Sell} />
              <Route path='/about' component={About} />
              <Route exact path='/shop' component={Search} />
              <Route path='/cart' component={ShoppingCart} />
              <Route
                path='/shipping-and-billing'
                component={ShippingAndBilling}
              />

              <Route path='/success' component={CheckoutSuccess} />

              <Route exact path='/dashboard'>
                <div className='dashboard'>
                  <OurSidebar />
                  <Dashboard />
                </div>
              </Route>

              <div id='business-pages'>
                <Route path='/business/:slug' component={OurSidebar} />
                <Route path='/business/research-and-shop' component={Search} />
                <Switch>
                  <Route
                    exact
                    path='/business/new'
                    component={RegisterBusiness}
                  />

                  <Route
                    exact
                    path='/business/:slug/products'
                    component={MyProducts}
                  />
                  <Route
                    exact
                    path={[
                      '/business/:slug/products/new',
                      '/business/:slug/products/edit/:id',
                    ]}
                    component={CreateProduct}
                  />
                  <Route
                    exact
                    path='/business/:slug/edit'
                    component={EditBusiness}
                  />
                  <Route exact path='/business/orders' component={Orders} />
                  <Route
                    exact
                    path='/business/:slug/stripe-connect-status'
                    component={StripeConnectStatus}
                  />
                  <Route
                    exact
                    path='/business/:slug?'
                    component={ProducerPublic}
                  />
                </Switch>
              </div>

              <Route path='/consumer' component={Consumer} />
              <Route path='/orders/:id' component={Orders} />
              <Footer />
            </React.Fragment>
          </Main>
        </Suspense>
      </Router>
    </AppProviders>
  );
};

export default App;
