import React, { useState, useEffect } from 'react';
import { useCart } from 'react-ecommerce-hook';
import AppContext from '../context/AppContext';
import ShoppingCartContext from '../context/ShoppingCartContext';
import { useUser } from '../dal/user';
import { client } from '../dal/apollo';
import { ApolloProvider } from '@apollo/client';
import { AlgoliaProvider } from './AlgoliaProvider';
function AppProviders({ children }: { children: React.ReactNode }) {
  const user = useUser();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const [appState, setAppState] = useState({
    user,
    businessProfile: {
      sellToStates: [],
    },
    sidebar: { sidebarIsOpen, setSidebarIsOpen },
  });

  const cart = {
    ...useCart(),
    removeAllFromCart(checkedProducers: any) {
      cart?.state?.addedIds?.forEach((id: number) => {
        const addedIdProducerId = cart?.state.products.find(
          (product: any) => product.id === id
        ).producedByBusinessId;
        if (checkedProducers[addedIdProducerId]) {
          cart.removeFromCart({ id });
        }
      });
    },
  };

  useEffect(() => {
    setAppState({
      ...appState,
      user,
    });
  }, [user, sidebarIsOpen]);

  if (user === undefined) return null;

  return (
    <AppContext.Provider value={{ appState, setAppState: setAppState }}>
      <ApolloProvider client={client}>
        <ShoppingCartContext.Provider value={cart}>
          <AlgoliaProvider>{children}</AlgoliaProvider>
        </ShoppingCartContext.Provider>
      </ApolloProvider>
    </AppContext.Provider>
  );
}

export default AppProviders;
