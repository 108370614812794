const SEARCH_TOKEN_KEY = "searchToken";
function storeValue(key: string, value: any): void {
  const now = new Date();

  // Store value and timestamp
  const item = {
    value: value,
    expiry: now.getTime() + 24 * 60 * 60 * 1000, // 24 hours in milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function retrieveValue(key: string): any {
  const itemStr = localStorage.getItem(key);

  // If the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // Compare the current time with the expiry time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete it from the storage and return null
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export { storeValue, retrieveValue, SEARCH_TOKEN_KEY};