import React from 'react'
import { orderBy } from 'lodash';
import { RefinementList } from 'react-instantsearch';
export default function SearchRefiner(props) {
    return (
        <div>
            <h3>{props.attribute}</h3>
            <RefinementList
                {...props}
                transformItems={items => orderBy(items, "label", "asc")}
                />
        </div>
    )
}
